<template>
    <div class="wrap">
    <!-- <div v-if="showContent" class="wrap"> -->
        <LoadingProgress v-show="loading" :loadingValue="loadedAssets/neededAssets * 100" />
        <DialogFrame id='dialog-logout-frame' ref='dialogLogout' :needClose='true' @emitConfirm='confirmLogout'>
            <div class="dialog-logout" slot='dialog-content'>
                <h6>Logout</h6>
                <div class="span-box">
                    <span>Come back soon!</span>
                    <span>Are you sure you want ot Log out? </span>
                </div>
            </div>
        </DialogFrame>
        <transition name="el-fade-in-linear">
            <div class="mask" v-show="extendMenu" @click="extendMenu=false"></div>
        </transition>
        <transition name="el-fade-in-linear">
            <div class="exhibition-intro" v-show="introToggle">
                <div class="pop-up-header">Introduction
                    <div class="close-btn" @click="introToggle=false">
                        <img src="@/assets/img/icon/crossW.svg" alt="" />
                    </div>
                </div>
                <div class="intro-content">
                    <div class="intro-p">
                        <p>{{introduction}}</p>
                    </div>
                    <div class="close-btn-spec" @click="introToggle=false">Close</div>
                </div>
            </div>
        </transition>
        <canvas id='indoor-scene'></canvas>
        <transition name="el-fade-in-linear">
            <div class="pop-up-tv" v-show="tvToggle">
                <div class="pop-up-header">{{tvName}}
                <div class="close-btn" @click="tvToggle = false;">
                    <img src="@/assets/img/icon/crossW.svg" alt="" />
                </div>
                </div>
                <iframe
                    id="tv-screen"
                    :src="tvurl"
                    frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
        </transition>
        <div class="pop-up-scene" v-show="popUpCanvasToggle">
            <div class="pop-up-header">Model Name
            <div class="close-btn" @click="closePopScene">
                <img src="@/assets/img/icon/crossW.svg" alt="" />
            </div>
            </div>
            <canvas id='pop-up-canvas'></canvas>
            <div class="hint">
                <div class="img-box mouse">
                    <img src="@/assets/img/icon/mouse.svg" alt="" class="mouse">
                    <span>Left-click to rotate, right-click to move and scroll wheel to zoom the screen</span>
                </div>
               <div class="img-box cube">
                    <img src="@/assets/img/icon/uCube.svg" alt="">
                    <span>Click on cube can see the description</span>
                </div>
            </div>
        </div>
        <div class="pop-up-board" v-show="boardToggle">
            <div class="close-btn" @click="boardToggle=false">
                <img src="@/assets/img/icon/crossW.svg" alt="" />
            </div>
            <!--<el-image :src="attachedBoardImg" class='board-img' fit='cover'></el-image> -->
            <iframe id="myFrame" :src='attachedWebsite'  class='board-img' fit='cover'></iframe>
            <div class="board-btn" @click="openWebsite()">New Tab</div>
            <div class="board-btn" @click="blockchainLike1()">LIKE</div>
            <div class="board-btn" @click="blockchainLike0()">DONT LIKE</div>
        </div>
        <div class="lobby-bar menu-bar">
            <div class="name-box pointer">
                <span><strong>{{exhibitionData.title}}</strong><br><span class="room-title">{{exhibitionData.roomName}}</span></span>
                <img src="@/assets/img/icon/location.svg" alt="">
            </div>
            <div class="icon-box pointer" @click='extendMenu=!extendMenu;'>
                <img src="@/assets/img/icon/menu.svg" alt="">
                <span>More</span>
            </div>
            
            <!-- <div class="icon-box pointer" @click='backToEntrance'>
                <img src="@/assets/img/icon/logout.svg" alt="">
                <span>Exit</span>
            </div> -->
            <!-- extended menu slots start -->
            <div class="extended-menu exhibition" v-show="extendMenu" :class="{hasLogin: login}">

                <div class="icon-box pointer floater" @click='switchMenuState(MENU_STATE.BLOCK, false)'>
                    <span>Block Info</span>
                    <img src='@/assets/img/icon/block-v2.svg' alt="">
                    <img src='@/assets/img/icon/block-v2_active.svg' alt="">
                </div>
                <div class="icon-box pointer floater" @click='shotPhoto'>
                    <span>Take Photo</span>
                    <img src='@/assets/img/icon/photo.png' alt="">
                    <img src='@/assets/img/icon/photo_active.png' alt="">
                </div>

                <div class="icon-box pointer floater" @click='switchMenuState(MENU_STATE.CHAT)'>
                    <span>Chat</span>
                    <img src='@/assets/img/icon/acc-info.png' alt="">
                    <img src='@/assets/img/icon/acc-info_active.png' alt="">
                </div>
                <!-- <div class="icon-box pointer floater" @click='logoutDialog'>
                    <span>{{login?'Logout':'Login'}}</span>
                    <img :src="login?require('@/assets/img/icon/logout.png'):require('@/assets/img/icon/login.svg')" alt="">
                    <img src="@/assets/img/icon/logout_active.png" alt="">
                </div> -->
            </div>
            <!-- extended menu slots end -->
            <transition name="el-fade-in-linear">
                <div class="main-menu" v-show="menuToggle&&!extendMenu">
                    <div class="title">{{menuTitle}}</div>
                    <!--  -->
                    <div class="block-item" v-show="menuState===MENU_STATE.BLOCK">
                        <div class="block-upper">
                            <img :src="exhibitionData.signboardUrl" alt="">
                            <div class="details">
                                <div class="name">{{exhibitionData.title}}</div>
                                <div class="theme"><img src="@/assets/img/icon/loc.svg" alt="">Theme - {{themeData.name}}</div>
                                <div><img src="@/assets/img/icon/email.svg" alt="">{{exhibitionData.email}}</div>
                                <div><img src="@/assets/img/icon/call.svg" alt="">{{exhibitionData.phone}}</div>
                            </div>
                        </div>
                        <div class="block-lower">{{exhibitionData.introduction}}</div>
                        <div class="btn-block">
                            <button class="pointer" @click='backToEntrance'>Exit Block</button>
                            <button class="pointer" @click='switchMenuState(MENU_STATE.CONTACT)'>Email
                                <img src="@/assets/img/icon/arr.svg" alt="">
                            </button>
                        </div>
                    </div>
                    <!--  -->
                    <div class="contact-item" v-show="menuState===MENU_STATE.CONTACT">
                        <div class="block-details">
                            <span class="name">{{exhibitionData.title}}</span>
                            <div class="contact-box">
                                <div class="email"><img src="@/assets/img/icon/email.svg" alt="">{{exhibitionData.email}}</div>
                                <div class="phone"><img src="@/assets/img/icon/call.svg" alt="">{{exhibitionData.phone}}</div>
                            </div>
                        </div>
                        <div class="name-form">
                            <div class="form-box">
                                <span>First name<span class='star'>*</span></span>
                                <input type="text" name="firstName" id="firstName" v-model="form.firstName" autocomplete="off">
                            </div>
                            <div class="form-box">
                                <span>Last name<span class='star'>*</span></span>
                                <input type="text" name="lastName" id="lastName" v-model="form.lastName" autocomplete="off">
                            </div>
                        </div>
                        <div class="email-box">
                            <span>Email<span class='star'>*</span></span>
                            <input type="email" name="email" id="email" v-model="form.email" autocomplete="off">
                        </div>
                        <div class="message-box">
                            <span>Message<span class='star'>*</span></span>
                            <textarea placeholder="type your message here2..." v-model="form.message" name="message" id="message"></textarea>
                        </div>
                        <div class="btn-block">
                            <button @click='switchMenuState(MENU_STATE.BLOCK)'>Cancel</button>
                            <button @click='sendMessage()'>Send!<img src="@/assets/img/icon/arr.svg" alt=""></button>
                        </div>
                        <transition name="el-fade-in-linear">
                            <div class="messages fail" v-show="showMessage===MESSAGE_STATE.FAIL">
                                <img src="@/assets/img/icon/fail.svg" alt="">
                                <span class="fail">Fail. {{login ? 'Please try again.' : 'Login required.'}}</span>
                            </div>
                        </transition>
                        <transition name="el-fade-in-linear">
                            <div class="messages success" v-show="showMessage===MESSAGE_STATE.SUCCESS">
                                <img src="@/assets/img/icon/success.svg" alt="">
                                <span class="success">Successfully sent!</span>
                            </div>
                        </transition>
                    </div>
                    <!--  -->
                    <div class="chat-item" v-show="menuState===MENU_STATE.CHAT">
                        <div class="chat-tags">
                            <div class="chats pointer" :class="{active:channel=='system'}" @click="channel='system'">System</div>
                            <div class="chats pointer" :class="{active:channel=='chat'}" @click="channel='chat'">Chat</div>
                        </div>
                        <div class="chat-box" v-show="channel=='chat'">
                            <div v-show="chatroomData.length" class="chat-container" v-for="item in chatroomData" :key="item.index" :class="{self:item.name===nickname}">
                                <div class="name">{{item.name}}</div>
                                <div class="messages">{{item.messages}}</div>
                                <div class="date">{{item.date}}</div>
                            </div>
                        </div>
                        <div class="chat-box" v-show="channel=='system'">
                            <div v-show="systemData.length" class="chat-container" v-for="item in systemData" :key="item.index">
                                <div class="name">{{item.name}}</div>
                                <div class="messages">{{item.messages}}</div>
                                <div class="date">{{item.date}}</div>
                            </div>
                        </div>
                        <div class="send">
                            <input type="text" name='chatMessage' v-model="chatMessage" placeholder="Say something..." autocomplete="off" v-on:keydown='keydownEvent'>
                            <img class='pointer' src="@/assets/img/icon/send.svg" alt="" @click="sendChatMessage">
                        </div>
                    </div>
                    <!--  -->
                    <div class="account-item" v-show="menuState===MENU_STATE.ACCOUNT_INFO">
                        <div class="edit-box">
                            <div class="info-box">
                                <span>Account ID</span>
                                <span>Saha12345678</span>
                            </div>
                            <div class="info-box">
                                <span>User Name</span>
                                <span>Saha</span>
                                <span>Edit</span>
                            </div>
                            <div class="info-box">
                                <span>Email</span>
                                <span>fake@gmail.com</span>
                                <span>Edit</span>
                            </div>
                            <div class="info-box">
                                <span>Password</span>
                                <span>************</span>
                                <span>Edit</span>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="account-item" v-show="false">
                        <div class="form-box">
                            <span>First name<span class='star'>*</span></span>
                            <input type="text" name="firstName" id="firstName" v-model="form.firstName" autocomplete="off">
                        </div>
                    </div>
                    <img src="@/assets/img/icon/close.svg" alt="" class="close pointer" @click='menuToggle=false'>
                </div>
            </transition>
        </div>
        <transition name="el-fade-in-linear"><div class="whole-screen-darkness" @click="clearUI" v-show="popUpCanvasToggle || tvToggle || introToggle || popUpCanvasToggle || formToggle || boardToggle"></div></transition>
        <!-- <transition name="el-fade-in-linear"><Tutorial v-if="slideToggle" @closeSlide="closeSlide" :displayType='`user`'/></transition> -->
        <ScreenShot :screenShotSrc="screenShotSrc" v-if="screenShotSrc!=''" @close="closeScreenShot"/>
       <!--  <Keyboard :keyInputs="keyInputs"  @emitKeepMoveCamera="emitKeepMoveCamera"
            @emitResetKeyboard="emitResetKeyboard"/> -->
    </div>
<!--     <div v-else>
        <p>請透過數位資產租借平台購票進入展間</p>
        <router-link :to="https://blockchain-asset.dlll.nccu.edu.tw/">Go</router-link>
    </div> -->
</template>
<style lang='scss'>
@import '@/assets/scss/exhibition.scss';
</style>
<script>
import * as Babylon from '@babylonjs/core';
import * as utils from "@/utils/main.js";
import * as np from "nipplejs";
import "@babylonjs/loaders";//Load External Assets.
import "@babylonjs/inspector";//Display fps, others.
import LoadingProgress from "@/components/LoadingProgress";
import Keyboard from "@/components/Keyboard.vue";
import {getSellerExhibition, HostUrl, sendMail, getUserAccByAdmin, exhInteract, checkTicket, exhEXP} from '@/api';
import { mapActions } from 'vuex';
import axios from "axios";
import DialogFrame from "@/components/DialogFrame.vue";
import ScreenShot from "@/components/ScreenShot.vue";
const DEFAULT_EXHIBITION_DATA = {
    address: '',
    email: '',
    link: '',
    phone: '',
    signboardUrl: '',
    title: '',
    introduction:'',
    roomName: '',
}
const DEFAULT_THEME_DATA = {
    description: '',
    key: '',
    name: '',
}
const DEFAUL_CHATROOM_DATA = [];
//const SEND_MESSAGE = "";
export default {
    components:{
        LoadingProgress, DialogFrame
    },
    data(){
        return{
            fps: 60,
            hostUrl: HostUrl,
            loadingScreen: null,
            engine: null,
            shadowGenerator: null,
            popEngine: null,
            canvas: null,
            scene: null,
            camera: null,
            popUpCanvasToggle: false,
            formToggle: false,
            basicMaterials:[],
            basicMeshes: [],
            form: {
                firstName:'',
                lastName:'',
                email:'',
                message:'',
            },
            loginDialog: false,
            attachedTV:null,
            attachedBoardImg: null,
            attachedWebsite: '',
            slideToggle: false,
            boardToggle: false,
            tvToggle: false,
            tvUrl: '',
            tvName: 'PV Screen',
            introToggle: false,
            title: 'Empty',
            dataX: 1,
            baseURL: process.env.BASE_URL,
            currentWindow: null,
            loading: false,
            introduction:'',
            preview:false,
            neededAssets: 99,
            loadedAssets: 0,
            cameraRadius: 80,
            hoverRoom: -1,
            hoverIcon: -1,
            menuToggle: false,
            menuState: 0,
            showMessage: 0,
            MENU_STATE: Object.freeze({
                'INTRO': 0,
                'CHAT': 1,
                'CONTACT': 2,
                'ACCOUNT_INFO': 3,
            }),
            MESSAGE_STATE: Object.freeze({
                'FAIL': 1,
                'SUCCESS': 2,
                'WARN': 3,
            }),
            exhibitionData: DEFAULT_EXHIBITION_DATA,
            themeData: DEFAULT_THEME_DATA,
            chatMessage: '',
            renderFrames: 0,
            character: null,
            move: {x:0,y:0},
            keyInputs: {},
            extendMenu: false,
            characterDestination: {
                distance:0,
                target:null,
            },
            chatroomData: DEFAUL_CHATROOM_DATA,
            //socket:null,
            socket: this.$store.state.socket,
            nickname: '黑創小夥伴'+Math.floor(Math.random()*99999),
            systemData:[],
            channel:"chat",
            screenShotSrc:"",
            hl:null,
            SEND_MESSAGE: "",
//////////////////////////////////////////////////////////////////////////////////////////////////////    
            // showContent: false
//////////////////////////////////////////////////////////////////////////////////////////////////////            
        }
    },
    methods:{
        ...mapActions(['setLogout']),
        // init(){
        //     const vm = this;
        //     if(this.$route.query.watchType==='preview') this.preview = true
        //     this.setEngine().then(()=>{
        //         vm.setScene();
        //         vm.setCamera();
        //         vm.setLightAndShadows();
        //         vm.setBasicMeshes();
        //         vm.setCharacter();
        //         //vm.getSellerExhibitions();
        //         //vm.setSockets();
        //     })
        // },
//////////////////////////////////////////////////////////////////////////////////////////////////////
        init(){
            const vm = this;
            let uid = window.localStorage.getItem('userId');
            let roomId = this.$route.query.id;
            let ticket = 0;
            console.log(uid);
            console.log(roomId);
             if(this.$route.query.watchType==='preview') this.preview = true
                    this.setEngine().then(()=>{
                        vm.setScene();
                        vm.setCamera();
                        vm.setLightAndShadows();
                        vm.setBasicMeshes();
                        vm.setCharacter();
                        //vm.getSellerExhibitions();
                        vm.setSockets();
                         })
                    
            // 驗票
            // let apiResponse = checkTicket(uid, roomId) // checkTicket API
            // apiResponse.then((data) => {
            //     ticket = data.data;
            //     console.log('Ticket')
            //     console.log(ticket)
            //     if (ticket === 0) { // 如果API返回0，則導向數位資產平台
            //         window.location.href = 'https://blockchain-asset-platform.dlll.nccu.edu.tw/';
            //         alert('您尚未購票，請購票！');
            //     } else { // 如果API返回1，則繼續執行
            //         if(this.$route.query.watchType==='preview') this.preview = true
            //         this.setEngine().then(()=>{
            //             vm.setScene();
            //             vm.setCamera();
            //             vm.setLightAndShadows();
            //             vm.setBasicMeshes();
            //             vm.setCharacter();
            //             //vm.getSellerExhibitions();
            //             //vm.setSockets();
            //         })
            //     } 
            // })
        },
//////////////////////////////////////////////////////////////////////////////////////////////////////
        setScene(){
            const vm = this;
            this.scene = new Babylon.Scene(this.engine);
            let helper = this.scene.createDefaultEnvironment({
                skyboxSize: 50,
                createSkybox: false,
                createGround: false,
                cameraExposure: 1,
                cameraContrast: 1.5,
            });
            this.scene.collisionsEnabled = true;
            this.hl = new Babylon.HighlightLayer("hl1", this.scene);
            this.scene.registerBeforeRender(()=>{
                const moveSpeed = 5;
                if(this.loadedAssets === this.neededAssets){
                    this.loading = false;
                }
                this.cameraRadius = this.camera.radius;
                if(this.renderFrames<30){
                    this.renderFrames++;
                }else{
                    this.scene.renderTargetsEnabled = false;
                }
                if(this.move.x||this.move.y){
                    this.character.moveWithCollisions(vm.character.forward.scaleInPlace(this.move.y*-1*moveSpeed));
                    this.character.moveWithCollisions(vm.camera.getDirection(new Babylon.Vector3(this.move.x*moveSpeed, 0, 0)));
                }
            });
            this.scene.actionManager = new Babylon.ActionManager(this.scene);
            this.scene.actionManager.registerAction(new Babylon.ExecuteCodeAction(Babylon.ActionManager.OnKeyDownTrigger, function (evt) {
                vm.keyInputs[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
            }));
            this.scene.actionManager.registerAction(new Babylon.ExecuteCodeAction(Babylon.ActionManager.OnKeyUpTrigger, function (evt) {
                vm.keyInputs[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
            }));
            this.scene.onPointerObservable.add((pointerInfo)=>{
                if(pointerInfo.type===Babylon.PointerEventTypes.POINTERTAP){
                    if(!pointerInfo.pickInfo) return;
                    this.interactWithMesh(pointerInfo?.pickInfo?.pickedMesh||null);
                }
            });
            window.scene = this.scene;
        },
        interactWithMesh(mesh){
            if(!mesh) return;
            
            const name = mesh.name;
	        //alert(name);
            console.log(mesh);


            //if(name.includes('Monitor') && mesh.videoLink){
            //if(name.includes('Screen') && mesh.videoLink){
            if(name.includes('Monitor')|| name.includes('Screen') || name.includes('Board')|| name.includes('Display') && mesh.website){
                //this.tvToggle = true;

                if(mesh.website.includes(".mp4")){
                    this.tvToggle = true;
                    this.tvUrl = mesh.website;
                    // this.attachedBoardImg = mesh.boardImg;
                    // this.attachedWebsite = mesh.website;
                    // this.boardToggle = true;
                }else if (mesh.website.includes("youtube") || mesh.website.includes("youtu.be")) {
                    this.tvToggle = true;
                    this.tvUrl = 'https://www.youtube.com/embed/'+this.getId(mesh.website)+'?modestbranding=1&autohide=1&showinfo=0';
                }
                else{
                    console.log(mesh.website)
                    this.attachedWebsite = mesh.website;
                    this.attachedBoardImg = mesh.boardImg;
                    this.boardToggle = true;
                }


            }
            //if(mesh.videoLink){
                //this.tvToggle = true;
                //this.tvUrl = 'https://www.youtube.com/embed/'+this.getId(mesh.videoLink)+'?modestbranding=1&autohide=1&showinfo=0';
           // }
            // if(name.includes('Poster')||name.includes('Board')){
            //     if(mesh.boardImg){
            //         this.attachedBoardImg = mesh.boardImg;
                    
            //         //this.attachedWebsite = "https://playground.babylonjs.com/"
            //         this.attachedWebsite = mesh?.website||'';
            //         this.boardToggle = true;
            //     }
            // }
            if(name.includes('lowpoly') && mesh.url || (mesh.type==='Customized') && mesh.url){
                this.popUpCanvasToggle = true;
                this.initPopUpScene({
                    url: '',
                    fileName: mesh.url,
                    modelName: mesh.modelName||'Unnamed',
                })
            }
            // if(name.includes('Board')){
            //     //mesh.website = "https://after-war-memory-hk.ccstw.nccu.edu.tw/item/19997/universal-viewer"
            //     if(mesh.website){
            //         this.attachedBoardImg = mesh.boardImg;
            //         this.attachedWebsite = mesh.website;
            //         this.boardToggle = true;
            //     }
            // }
            if(name.includes('Counter')||name.includes('Info')||name.includes('info')){
                this.toggleForm();
            }
        },
        setLightAndShadows(){
            
            let hLight = new Babylon.HemisphericLight('hLight', new Babylon.Vector3(1,1,1), this.scene);
            hLight.intensity = 1;
            let light = new Babylon.DirectionalLight("light", new Babylon.Vector3(0, -2, 0), this.scene);
            light.intensity = 2;
            light.position = new Babylon.Vector3(0, 50, 0);

            this.shadowGenerator = new Babylon.ShadowGenerator(1024, light);
            this.shadowGenerator.useBlurExponentialShadowMap = false;
            this.shadowGenerator.useKernelBlur = false;
            this.shadowGenerator.blurKernel = 64;
            this.shadowGenerator.usePoissonSampling = false;
            this.shadowGenerator.getShadowMap().refreshRate = 5;
            
        },
         setSockets(){
            const vm = this;
            // Chatroom
            this.SEND_MESSAGE = this.$route.query.id;
            console.log(this.SEND_MESSAGE)
            this.socket.emit("Welcome", this.SEND_MESSAGE);
            this.socket.on('get_message', (data)=>{
                console.log(data);
                if(data.name != this.nickname){
                         this.updateChatMessage({
                    name: data.name,
                    messages: data.messages,
                    room: this.SEND_MESSAGE,
                    //theme: data.theme,
                }, true)
                }
            });
        },
        async setEngine(){
            this.canvas = document.getElementById('indoor-scene');
            this.engine = await new Babylon.Engine(this.canvas, true, {preserveDrawingBuffer: true, stencil: true }, true);
            this.loadingScreen = new utils.CustomLoadingScreen();
            this.engine.loadingScreen = this.loadingScreen;//disable babylon's default loading effect
            this.engine.runRenderLoop(()=>{
                this.scene.render();
                this.engine.resize();
            })
        },
        /*setCamera(){
            this.camera = new Babylon.ArcRotateCamera('arcCamera', 0, 0, 40, new Babylon.Vector3(0,0,0), this.scene);
            this.camera.lowerRadiusLimit = 10;
            this.camera.beta = Math.PI/3;
            this.camera.alpha = Math.PI/2;
            this.camera.radius = this.cameraRadius;
            this.camera.setTarget(Babylon.Vector3.Zero());
            this.camera.upperRadiusLimit = 70;
            this.camera.wheelDeltaPercentage = 0.01;
            this.camera.lowerBetaLimit = 0.5;
            this.camera.upperBetaLimit = 1.5;
            this.camera.attachControl(this.canvas, true);
            this.camera.panningSensibility = 75;
            window.camera = this.camera;
        },*/
	setCamera(){
            console.log(this.character);
            this.camera = new Babylon.ArcRotateCamera('playerCamera',
                                                        -(Math.PI/2), 
                                                        Math.PI/3, 50, 
                                                        // this?.character||new Babylon.Vector3.Zero(),
                                                        this?.character || new Babylon.Vector3(0, 12, 0),
                                                        scene, 
                                                        true
                                                    );
            this.camera.lowerRadiusLimit = 1;
            this.camera.upperRadiusLimit = 10;
            //this.camera.lowerBetaLimit = Math.PI/6;
            //this.camera.lowerBetaLimit = Math.PI/10;
            //this.camera.upperBetaLimit = Math.PI/2.5;
            //this.camera.upperBetaLimit = Math.PI/2;
            this.camera.minZ = .01;
            this.camera.checkCollisions = true;
            this.camera.wheelDeltaPercentage = 0.01;
            //this.camera.angularSensibilityX = 10000;
            //this.camera.angularSensibilityY = 10000;

            // this.camera.inputs.remove(this.camera.inputs.attached.mousewheel) // Disable mousewheel
            //為何要禁掉camera滾輪縮放？

            this.camera.attachControl(this.canvas,true);
            //this.camera.checkCollisions = true;
            //this.camera.invertRotation = true;
            //this.camera.collisionRadius = new Babylon.Vector3(0.5, 0.5, 0.5);
            //this.camera.inputs.removeByType('ArcRotateCameraPointersInput');
            this.camera.inputs.removeByType("ArcRotateCameraKeyboardMoveInput");
            console.log(this.camera);
            window.camera = this.camera;
        },
	/*setCamera(){
            
            //this.camera = new Babylon.ArcRotateCamera('playerCamera',-(Math.PI/2), Math.PI/3, 50, this?.character||new Babylon.Vector3.Zero(),scene, true);
            this.camera = new Babylon.FreeCamera('playerCamera',-(Math.PI/2), Math.PI/3, 50, this?.character||new Babylon.Vector3.Zero(),scene, true);
            this.camera.radius = 6
            //this.camera.lowerRadiusLimit = 1;
            //this.camera.upperRadiusLimit = 6;
            //this.camera.lowerBetaLimit = Math.PI/6;
            //this.camera.lowerBetaLimit = Math.PI/10;
            //this.camera.upperBetaLimit = Math.PI/2.5;
            //this.camera.upperBetaLimit = Math.PI/2;
            //this.camera.wheelDeltaPercentage = 0.01;
            //this.camera.angularSensibilityX = 10000;
            //this.camera.angularSensibilityY = 10000;
            //this.camera.inputs.remove(this.camera.inputs.attached.mousewheel) // Disable mousewheel
            this.camera.attachControl(this.canvas,true);
            this.camera.checkCollisions = true;
            //this.camera.invertRotation = true;
            //this.camera.collisionRadius = new Babylon.Vector3(0.5, 0.5, 0.5);
            //this.camera.inputs.removeByType('ArcRotateCameraPointersInput');
            //this.camera.inputs.removeByType("ArcRotateCameraKeyboardMoveInput");
            window.camera = this.camera;
        },*/
        toggleForm(){
            this.getUserData();
            if(this.login){
                this.formToggle = true;
            }
            else{
                this.loginDialog = true;
            }
        },
        initPopUpScene(data){
            this.engine.stopRenderLoop();//stop main scene rendering;
            let canvas = document.getElementById('pop-up-canvas');
            this.popEngine = new Babylon.Engine(canvas, true);
            let scene = new Babylon.Scene(this.popEngine);
            scene.name = 'PopScene';
            window.scenes = scene;

            let camera = new Babylon.ArcRotateCamera('arcCameraPop', 0, 0, 7, new Babylon.Vector3(0,0,0), scene);
            //camera.attachControl(canvas, true);
            //camera.lowerRadiusLimit = 2;
            //camera.beta = Math.PI/3;
            //camera.alpha = Math.PI/2;
            //camera.upperRadiusLimit = 15;
            //camera.wheelDeltaPercentage = 0.01;

            let pixelRatio = window.devicePixelRatio;
            // Set the render engine to scale properly
            engine.setHardwareScalingLevel(1 / pixelRatio); // This makes the scene and GUI clear on a HDPI display, but its no longer the correct size.

            let light = new Babylon.HemisphericLight('',new Babylon.Vector3.Zero(), scene);
            light.intensity = 1.2;

            let helper = scene.createDefaultEnvironment({
                groundShadowLevel: 0,
                groundColor: new Babylon.Color3.FromHexString('#fff'),
                skyboxColor: new Babylon.Color3.FromHexString('#fff'),
                skyboxSize: 50,   
            });
            Babylon.SceneLoader.ImportMesh('', data.url, data.fileName, scene, (container)=>{
                let rootMesh = container.filter(e=>e.name==='__root__')[0];
                rootMesh.setPivotPoint(new Babylon.Vector3.Zero());
                rootMesh.position.set(0,0,0);
                container.forEach(e=>{
                    if(e.name.includes('lowpoly')){
                        e.setEnabled(false);
                    }
                })
                //scene.stopAllAnimations();
            },(prog)=>{
                console.log(prog);
            },(error)=>{
                console.log(error);
            })
            this.popEngine.runRenderLoop(()=>{
                scene.render();
                this.popEngine.resize();
            })
            scene.onPointerPick = (event, pickResult)=>{
                if(!pickResult.hit) return;
                let name = pickResult.pickedMesh.name;
                // let anim = null;
                if(name.includes('dot')){
                    let anim = scene.getAnimationGroupByName((name.split('_')[0]).replace('txt','Anim'));
                    if(anim && !anim.isPlaying){//Avoid animation playing while clicking trigger;
                        if(anim.playback){
                            anim.start(false, 1, anim.to, anim.from, true);
                        }else{
                            anim.start(false, 1, anim.from, anim.to, true);
                        }
                        anim.onAnimationEndObservable.addOnce(()=>{
                            anim.stop();
                        })
                        anim.playback = anim.playback ? false:true;
                    }
                    /* 動畫聚焦 */
                    // this.animateCameraToPosition(camera, 1, 1.8, pickResult.pickedPoint);
                    // anim.onAnimationEndObservable.addOnce(()=>{
                    //     ele.goToFrame(ele.to);
                    //     ele.playback = anim.playback?false:true;
                    // });
                }
            }
        },
        smoothRotation(value){
            let perFrame = 1;
            let seconds = perFrame * 0.7;
            Babylon.Animation.CreateAndStartAnimation('rotateAnim', this.camera, 'alpha', perFrame, seconds, this.camera.alpha, this.camera.alpha+value, 2, new Babylon.SineEase(),()=>{});
        },
        smoothZoom(value){
            let perFrame = 1;
            let seconds = perFrame * 0.7;
            Babylon.Animation.CreateAndStartAnimation('zoomAnim', this.camera, 'radius', perFrame, seconds, this.camera.radius, this.camera.radius+value, 2, new Babylon.SineEase(),()=>{});
        },
        clearUI(){
            this.popUpCanvasToggle = this.tvToggle = this.introToggle = this.popUpCanvasToggle = this.formToggle = this.boardToggle = false;
        },
        animateCameraTargetToPosition(cam, speed, frameCount, newPos) {
            let ease = new Babylon.SineEase();
        },
        animateCameraToPosition(cam, speed, frameCount, newPos) {
            let ease = new Babylon.SineEase();
        },
        async sendMessage(){
            this.clearUI();
            const vm = this;
            vm.getUserData();
            await sendMail(this.form, this.$route.query.id).then((res)=>{
                vm.showMessage = this.MESSAGE_STATE.SUCCESS;
                setTimeout(()=>{
                    vm.showMessage = 0;
                }, 1500)
            }).catch(err=>{
                vm.showMessage = this.MESSAGE_STATE.FAIL;
                setTimeout(()=>{
                    vm.showMessage = 0;
                }, 1500)
            })
        },
        setBasicMeshes(){
            Babylon.SceneLoader.ImportMesh("",this.baseURL+'static/models/component/model/', 'Geo.glb', this.scene, (container)=>{
                container.forEach((e,index)=>{
                    e.setEnabled(false);
                })
                this.setBasicMaterial();
            })
        },
        setBasicMaterial(){
            this.basicMaterials = [];
            Babylon.SceneLoader.ImportMesh('', this.baseURL+'static/models/material/', 'Material.glb', this.scene, (container)=>{
                for(let i=0;i<container.length;i++){
                    let materialName = container[i].name;
                    if(materialName.includes("Concrete")||materialName.includes("Floor")||materialName=='Grass-1'||materialName.includes("Metal")||materialName=='Plastic-1'||materialName.includes('Wood')||materialName.includes('Marble')||materialName=='Leather-1'){
                        if(container[i].material){
                            this.basicMaterials.push(container[i].material);
                            container[i].setEnabled(false);
                        }
                    }
                }
                this.getSellerExhibitions();
            })
        },
        setCharacter(){
            const vm = this;
            // Babylon.SceneLoader.ImportMeshAsync('', 'static/models/', 'avatar.glb', this.scene, (prog)=>{})
            Promise.all([
                Babylon.SceneLoader.ImportMeshAsync('', 'static/models/', 'avatar.glb', this.scene, (prog)=>{}),
                Babylon.SceneLoader.ImportMeshAsync('', 'static/models/', 'cameraLocker.glb', this.scene, (prog)=>{})
            ])
            .then((container)=>{
                console.log(container);
                this.character = container[0].meshes[0];
                this.characterHead = container[0].meshes[2];

                this.cameraLocker = container[1].meshes[0];
                
                // this.cameralocker = Babylon.MeshBuilder.CreateSphere("cameraLocker", {diameterX: 0.1, diameterY: 0.1, diameterZ: 0.1});
                // cameralocker.position = new Babylon.Vector3(0, 0, -5);

                console.log(this.character);
                this.character.scaling.scaleInPlace(1);
                this.character.checkCollisions = true;
                console.log('aaaaa');
                //this.character.billboardMode = Babylon.Mesh.BILLBOARDMODE_Y;
                //this.character.billboardMode=2;
                
                
                //this.camera.setPosition(this.character.position);
                //this.character.rotation.y = this.camera.rotation.y;
                
                this.character.position = new Babylon.Vector3(0, 0, -5);
                this.cameraLocker.position = new Babylon.Vector3(0,1.4,-5);

                // console.log(this.character.position.y);
                // this.camera.setPosition(new Babylon.Vector3(0,7,-30));
                this.camera.setPosition(new Babylon.Vector3(0,2.5,-8));

                console.log('1');
                
                // this.camera.target = this.character;
                this.camera.target = this.cameraLocker;
                console.log('2');
                
                window.character = this.character;
                console.log('3');
                
                window.cameraLocker = this.cameraLocker;
                console.log('4');

                // window.cameralocker = this.cameralocker;
                // console.log('5');
                // var combineMesh = Babylon.Mesh.MergeMeshes([this.character, this.character2], true, false, false, false, true);
                // console.log('5');
                // window.combineMesh = combineMesh;
                // console.log('6');
                
            }).catch((err)=>{
                
                console.log('Err while character model loading');
                console.log(err);
            })
            //character movement settings
            this.scene.onBeforeRenderObservable.add(() => {
                let keydown = false;
                let turning_flag = false;
                let cameraDirection, meshDirection, cameraAngleDegrees, meshangleDegrees;
                const moveSpeed = 0.1;
                const rotateSpeed = 0.02;
                const rotateRatio = !vm.invertRotation ? 0.4 : 0.4;
                const distanceFromCenter = Babylon.Vector3.Distance(this?.character?.position||Babylon.Vector3.Zero(), Babylon.Vector3.Zero());
                if(distanceFromCenter + moveSpeed >= 50){
                    //this.character.position = new Babylon.Vector3.Zero();
		            // this.character.position = new Babylon.Vector3(0,10.0);
                }


                if (vm.keyInputs["w"] || vm.keyInputs["W"]) {
                    this.characterDestination.distance = 0;

                    this.characterDestination.distance = 0;
                    cameraDirection = vm.camera.getDirection(Babylon.Vector3.Forward());
                    meshDirection = vm.character.getDirection(Babylon.Vector3.Forward());
                    cameraAngleDegrees = Babylon.Tools.ToDegrees(Math.atan2(cameraDirection.x, cameraDirection.z));
                    meshangleDegrees = Babylon.Tools.ToDegrees(Math.atan2(meshDirection.x, meshDirection.z));

                    if (Math.abs(meshangleDegrees - cameraAngleDegrees) < 0.1) {
                        vm.character.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//角色模型向前
                        vm.cameraLocker.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//追蹤點向前
                    }else{
                        vm.character.rotate(
                                Babylon.Vector3.Up(), 
                                ((cameraAngleDegrees -meshangleDegrees)/360)
                            );
                    }

                    keydown = true;
                }
                if (vm.keyInputs["s"] || vm.keyInputs["S"]) {
                    this.characterDestination.distance = 0;

                    cameraDirection = vm.camera.getDirection(Babylon.Vector3.Forward());
                    meshDirection = vm.character.getDirection(Babylon.Vector3.Forward());
                    cameraAngleDegrees = Babylon.Tools.ToDegrees(Math.atan2(cameraDirection.x, cameraDirection.z));
                    meshangleDegrees = Babylon.Tools.ToDegrees(Math.atan2(meshDirection.x, meshDirection.z));
                    cameraAngleDegrees = (cameraAngleDegrees >0 ) ? cameraAngleDegrees-180 : cameraAngleDegrees+180; //角色模型轉身向後
                    
                    if (Math.abs(meshangleDegrees - cameraAngleDegrees) < 0.1) {
                        vm.character.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//角色模型向前
                        vm.cameraLocker.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//追蹤點向前
                    }else{
                        vm.character.rotate(
                                Babylon.Vector3.Up(), 
                                ((cameraAngleDegrees -meshangleDegrees)/360)
                            );
                    }
                    keydown = true;
                }
                if (vm.keyInputs["a"] || vm.keyInputs["A"]) {//左轉
                    cameraDirection = vm.camera.getDirection(Babylon.Vector3.Forward());
                    meshDirection = vm.character.getDirection(Babylon.Vector3.Forward());
                    cameraAngleDegrees = Babylon.Tools.ToDegrees(Math.atan2(cameraDirection.x, cameraDirection.z));
                    meshangleDegrees = Babylon.Tools.ToDegrees(Math.atan2(meshDirection.x, meshDirection.z));

                    let targetAngleDegrees = ((cameraAngleDegrees - 90) < -180) ? (360 + (cameraAngleDegrees - 90)) : (cameraAngleDegrees - 90);
                    //角色模型轉身向左

                    // console.log(cameraAngleDegrees);
                    // console.log(targetAngleDegrees);
                    // console.log(meshangleDegrees);
                    // console.log(targetAngleDegrees -meshangleDegrees);
                    // cameraAngleDegrees = (cameraAngleDegrees >0 ) ? cameraAngleDegrees-90 : cameraAngleDegrees+90;
                    // console.log(meshangleDegrees + ' - ' + cameraAngleDegrees + ' = ' + Math.abs(meshangleDegrees - cameraAngleDegrees));

                    if ((Math.abs(meshangleDegrees - targetAngleDegrees) < 0.1)) {
                        vm.character.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//角色模型向前
                        vm.cameraLocker.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//追蹤點向前
                    }else{
                        vm.character.rotate(
                                Babylon.Vector3.Up(), 
                                ((targetAngleDegrees -meshangleDegrees)/360)
                            );
                    }

                }
                if (vm.keyInputs["d"] || vm.keyInputs["D"]) {//右轉
                    cameraDirection = vm.camera.getDirection(Babylon.Vector3.Forward());
                    meshDirection = vm.character.getDirection(Babylon.Vector3.Forward());
                    cameraAngleDegrees = Babylon.Tools.ToDegrees(Math.atan2(cameraDirection.x, cameraDirection.z));
                    meshangleDegrees = Babylon.Tools.ToDegrees(Math.atan2(meshDirection.x, meshDirection.z));

                    let targetAngleDegrees = ((cameraAngleDegrees + 90) > 180) ? ((cameraAngleDegrees + 90) - 360) : (cameraAngleDegrees + 90);
                    //角色模型轉身向右

                    // console.log(cameraAngleDegrees);
                    // console.log(targetAngleDegrees);
                    // console.log(meshangleDegrees);
                    // console.log(targetAngleDegrees -meshangleDegrees);
                    // cameraAngleDegrees = (cameraAngleDegrees >0 ) ? cameraAngleDegrees+90 : cameraAngleDegrees-90; //角色模型轉身向右
                    // console.log(meshangleDegrees + ' - ' + cameraAngleDegrees + ' = ' + Math.abs(meshangleDegrees - cameraAngleDegrees));
                    
                    if ((Math.abs(meshangleDegrees - targetAngleDegrees) < 0.1)) {
                        vm.character.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//角色模型向前
                        vm.cameraLocker.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//追蹤點向前
                    }else{
                        vm.character.rotate(
                                Babylon.Vector3.Up(), 
                                ((targetAngleDegrees -meshangleDegrees)/360)
                            );
                    }

                }
                // if (vm.keyInputs["a"] || vm.keyInputs["A"]) {
                //     this.characterDestination.distance = 0;

                //     cameraDirection = vm.camera.getDirection(Babylon.Vector3.Forward());
                //     meshDirection = vm.character.getDirection(Babylon.Vector3.Forward());
                //     cameraAngleDegrees = Babylon.Tools.ToDegrees(Math.atan2(cameraDirection.x, cameraDirection.z));
                //     meshangleDegrees = Babylon.Tools.ToDegrees(Math.atan2(meshDirection.x, meshDirection.z));
                //     cameraAngleDegrees = (cameraAngleDegrees >0 ) ? cameraAngleDegrees+90 : cameraAngleDegrees-90; //角色模型轉身向左

                //     if (Math.abs(meshangleDegrees - cameraAngleDegrees) < 0.1) {
                //         vm.character.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//角色模型向前
                //         vm.cameraLocker.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//追蹤點向前
                //     }else{
                //         vm.character.rotate(
                //                 Babylon.Vector3.Up(), 
                //                 ((cameraAngleDegrees -meshangleDegrees)/360)
                //             );
                //     }

                //     // vm.character.moveWithCollisions(vm.camera.getDirection(new Babylon.Vector3(-moveSpeed, 0, 0)));//鏡頭左
                //     // vm.cameraLocker.moveWithCollisions(vm.camera.getDirection(new Babylon.Vector3(-moveSpeed, 0, 0)));//追蹤點鏡頭左
                //     keydown = true;
                // }
                // if (vm.keyInputs["d"] || vm.keyInputs["D"]) {
                //     this.characterDestination.distance = 0;
                //     // vm.character.moveWithCollisions(vm.camera.getDirection(new Babylon.Vector3(moveSpeed, 0, 0)));//鏡頭右
                //     // vm.cameraLocker.moveWithCollisions(vm.camera.getDirection(new Babylon.Vector3(moveSpeed, 0, 0)));//追蹤點鏡頭右
                //     cameraDirection = vm.camera.getDirection(Babylon.Vector3.Forward());
                //     meshDirection = vm.character.getDirection(Babylon.Vector3.Forward());
                //     cameraAngleDegrees = Babylon.Tools.ToDegrees(Math.atan2(cameraDirection.x, cameraDirection.z));
                //     meshangleDegrees = Babylon.Tools.ToDegrees(Math.atan2(meshDirection.x, meshDirection.z));
                //     cameraAngleDegrees = (cameraAngleDegrees >0 ) ? cameraAngleDegrees-90 : cameraAngleDegrees+90; //角色模型轉身向右

                //     if (Math.abs(meshangleDegrees - cameraAngleDegrees) < 0.1) {
                //         vm.character.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//角色模型向前
                //         vm.cameraLocker.moveWithCollisions(vm.character.forward.scaleInPlace(moveSpeed));//追蹤點向前
                //     }else{
                //         vm.character.rotate(
                //                 Babylon.Vector3.Up(), 
                //                 ((cameraAngleDegrees -meshangleDegrees)/360)
                //             );
                //     }
                //     keydown = true;
                //     // console.log(vm.character);
                // }
                if (vm.keyInputs["q"] || vm.keyInputs["Q"] || vm.keyInputs["ArrowLeft"]) {
                    this.characterDestination.distance = 0;
                    vm.character.rotate(Babylon.Vector3.Up(), -rotateSpeed);
                    this.camera.alpha -= 0.05 * (vm.invertRotation ? rotateRatio : -rotateRatio);
                    //this.camera.alpha += 0.03;
                    keydown = true;
                }
                if (vm.keyInputs["e"] || vm.keyInputs["E"] || vm.keyInputs["ArrowRight"]) {
                    this.characterDestination.distance = 0;
                    vm.character.rotate(Babylon.Vector3.Up(), +rotateSpeed);
                    this.camera.alpha += 0.05 * (vm.invertRotation ? rotateRatio : -rotateRatio);
                    keydown = true;
                }
                if (vm.keyInputs["["] || vm.keyInputs["ArrowUp"]) {
                            this.camera.beta -= this.camera.beta > 0.7 ? 0.05 : 0;
                            keydown = true;
                        }
                if (vm.keyInputs["]"] || vm.keyInputs["ArrowDown"]) {
                            this.camera.beta += this.camera.beta < 1.3 ? 0.05 : 0;
                            keydown = true;
                        }
		
            });
        },
        closeSlide(){
            this.slideToggle = false;
        },
        async getUserData(){
            if(this.userId===null) return;
            let res = await getUserAccByAdmin(this.userId);
            this.form.firstName = res.data.data.nickname;
            this.form.lastName = res.data.data.nickname;
            this.form.email = res.data.data.email;
            this.form.message = '';
            this.nickname = res.data.data.nickname;
        },
        async getSellerExhibitions(){
            const roomId = this.$route.query.room;
            const vm = this;
            let imgList = [];
            let room_id = this.$route.query.id;
            // await exhEXP(room_id).then((data) => {
            //     // console.log(data.data);
            //     imgList = Object.values(data.data) // 取出所有網址
            //     console.log(imgList);
            // })
            await getSellerExhibition({}, vm.$route.query.id).then(res=>{
                vm.exhibitionData = res?.data?.data?.sellerProfile || DEFAULT_EXHIBITION_DATA;
                vm.themeData = res?.data?.data?.theme || DEFAULT_THEME_DATA;
                vm.exhibitionData.signboardUrl = res?.data?.data?.signboardUrl || '';
                vm.exhibitionData.title = res?.data?.data?.title || '';
                vm.exhibitionData.roomName = res?.data?.data?.rooms[roomId-1].name || '';
                vm.exhibitionData.introduction = res?.data?.data?.introduction || '';
                axios.get(res.data.data.rooms[roomId-1].sceneUrl, {
                    transformRequest: (data, headers) => {
                        delete headers.common['Authorization'];
                    }
                })
                .then((res) => { 
                    let mesh = res.data.meshData;
                    if(!mesh){
                        vm.backToEntrance();
                        return;
                    }
                    vm.neededAssets = mesh.length;
                    vm.setEnvMap(res.data.bgc);
                    mesh.forEach((e,index)=>{ //看mesh裡面有什麼
                        if(index+1 == mesh.length)this.$store.dispatch('loader/hide');
                        if(e.type=='cus'){
                            Babylon.SceneLoader.ImportMesh('', vm.hostUrl+e.url,e.fileName,vm.scene,(container)=>{
                                let thisMesh = container.filter(e=>e.name===('__root__'))[0];
                                thisMesh.rotationQuaternion = null;
                                thisMesh.position.y = e.position.y;
                                thisMesh.position.x = e.position.x;
                                thisMesh.position.z = e.position.z;
                                thisMesh.rotation.y = e.rotation.y;
                                thisMesh.rotation.x = e.rotation.x;
                                thisMesh.rotation.z = e.rotation.z;
                                thisMesh.scaling.y = e.scaling.y;
                                thisMesh.scaling.x = e.scaling.x*-1;
                                thisMesh.scaling.z = e.scaling.z;
                                thisMesh.checkCollisions = true;
                                vm.loadedAssets++;
                                container.forEach(ele=>{
                                    vm.shadowGenerator.getShadowMap().renderList.push(ele);
                                    vm.shadowGenerator.addShadowCaster(ele);
                                    ele.url = e.url;
                                })
                                
                                //vm.scene.stopAllAnimations();
                            },null,(error)=>{
                                vm.loadedAssets++;
                            });
                        }
                        else if(e.type=='notGeo'){
                            Babylon.SceneLoader.ImportMesh('', e.url,e.fileName,vm.scene,(container)=>{
                                let rootMesh = container.filter(e=>e.name===('__root__'))[0];//必然存在
                                let imgMesh = container.filter(e=>e.name.includes('UploadHere'))[0];
                                let colorMeshs = container.filter( e => e.name.includes('Color')|| e.name.includes('color'));
                                if(imgMesh){
                                    if(e.imgMaterial){
                                        let imgMat = new Babylon.StandardMaterial('', vm.scene);
                                        imgMat.diffuseTexture = new Babylon.Texture(e.imgMaterial, vm.scene);
                                        imgMat.emissiveTexture = new Babylon.Texture(e.imgMaterial, vm.scene);
                                        imgMat.diffuseTexture.vScale = -1;
                                        imgMat.emissiveTexture.vScale = -1;
                                        imgMesh.material = imgMat;
                                    }
                                }
                                if(colorMeshs){
                                    colorMeshs.forEach((item)=>{
                                        item.material = vm.basicMaterials[e.outputMaterial];
                                    })
                                }
                                else{
                                    let colorMat = new Babylon.StandardMaterial('', vm.scene);
                                    colorMat.diffuseColor = new Babylon.Color3.FromHexString(e.color);
                                    colorMeshs.forEach((item)=>{
                                        item.material = colorMat;
                                    })
                                }
                                rootMesh.checkCollisions = true;
                                rootMesh.rotationQuaternion = null;
                                rootMesh.position.y = e.position.y;
                                rootMesh.position.x = e.position.x;
                                rootMesh.position.z = e.position.z;
                                rootMesh.rotation.y = e.rotation.y;
                                rootMesh.rotation.x = e.rotation.x;
                                rootMesh.rotation.z = e.rotation.z;
                                rootMesh.scaling.y = e.scaling.y;
                                rootMesh.scaling.x = e.scaling.x*-1;
                                rootMesh.scaling.z = e.scaling.z;
                                vm.loadedAssets++;
                                container.forEach(ele=>{
                                    if(ele){
                                        ele.videoLink = e.videoLink;
                                        ele.boardImg = e.imgMaterial;
                                        ele.website = e.website;
                                        vm.shadowGenerator.getShadowMap().renderList.push(ele);
                                        vm.shadowGenerator.addShadowCaster(ele);
                                    }    
                                });
                                // console.log(rootMesh);
                                // console.log(imgMesh);
                                // console.log(colorMeshs);
                            },null,(error)=>{
                                vm.loadedAssets++;
                            })
                        }
                        else if(e.type=='Geo'){
                            let container = new Babylon.Mesh(e.parentName,vm.scene);
                            let cloneMesh = vm.scene.getMeshByName(e.childName).clone();
                            cloneMesh.setEnabled(true);
                            cloneMesh.receiveShadows = true;
                            if(e.color){
                                let colorMat = new Babylon.StandardMaterial('', vm.scene);
                                colorMat.diffuseColor = new Babylon.Color3.FromHexString(e.color);
                                cloneMesh.material = colorMat;
                            }
                            else{
                                cloneMesh.material = vm.basicMaterials[e.outputMaterial];
                            }
                            vm.shadowGenerator.getShadowMap().renderList.push(cloneMesh);
                            vm.shadowGenerator.addShadowCaster(cloneMesh);

                            container.addChild(cloneMesh);
                            container.checkCollisions = true;
                            container.position.y = e.position.y;
                            container.position.x = e.position.x;
                            container.position.z = e.position.z;
                            container.rotation.y = e.rotation.y;
                            container.rotation.x = e.rotation.x;
                            container.rotation.z = e.rotation.z;
                            container.scaling.y = e.scaling.y;
                            container.scaling.x = e.scaling.x*-1;
                            container.scaling.z = e.scaling.z;
                            vm.loadedAssets++;
                        }


                        console.log('...');
                        console.log(mesh);
                        let imgList = [];
                        let room_id = this.$route.query.id;
                        // exhEXP(room_id).then((data) => {
                        //     // console.log(data.data);
                        //     imgList = Object.values(data.data) // 取出所有網址
                        //     console.log(imgList);
                        // })
                        // mesh.forEach(obj => {
                        //     console.log(obj.imgMaterial);
                        //     if (imgList.includes(obj.imgMaterial)) {
                        //     // Do nothing if imgMaterial and website are already in imgList
                        //         console.log("Image in Exp");
                        //     } else {
                        //         console.log("Image not in Exp");
                        //         obj.imgMaterial = "";
                        //         obj.website = "";
                        //     }

                        //     console.log(item.imgMaterial)
                        //     if (imgList.includes(item.imgMaterial)) {
                        //         // 如果包含指定的網址，執行對應的程式碼
                        //         console.log("Image in Exp");
                        //     } else {
                        //         // 如果不包含指定的網址，將imgMaterial設為空字串
                        //         item.imgMaterial = '';
                        //         console.log("Image not in Exp");
                        //     }

                        // });




                    })
                }).catch((error)=>{
                    if(error.response){
                        vm.backToEntrance();
                    }
                })
                //vm.scene.stopAllAnimations();
            }).catch((error)=>{
                if(error.response){
                    alert(error.response.data.message);
                    window.location.href = location.protocol+'//'+window.location.host+'/vendor/';
                }
            });
        },
        backToEntrance(){
            this.$router.push({name: 'Entrance'});
        },
        async setEnvMap(env){
            new Babylon.PhotoDome("hdrSkyBox", `/static/models/scene/${env}/${env}.jpg`,{
                resolution: 32,
                size: 1000
            },this.scene);
            let model = {
                name:`${env}`,
                url:`static/models/scene/${env}/`,
                fileName:`${env}.glb`
            }
            await this.loadPlayground({
                url: modelUrl+model.url,
                fileName : model.fileName,
                pos: new Babylon.Vector3(0,0,0),
                scene: this.scene,
                name:model.name,
                type:'playground'
            },status);
        },
        loadPlayground(data,status){
            this.loadAni = true
            let vm = this
            let loader = Babylon.SceneLoader.ImportMesh('',data.url,data.fileName,this.scene,(newMeshes)=>{
                let meshes = new Babylon.Mesh("playground", this.scene);
                newMeshes.forEach((item)=>{
                    meshes.addChild(item)
                    vm.shadowGenerator.addShadowCaster(item);
                    vm.shadowGenerator.getShadowMap().renderList.push(item);
                })
                this.loadAni = false
            })
        },
        getId(url) {
            if(!url || url===undefined) return;
            let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            let match = url.match(regExp);

            return (match && match[2].length === 11) ? match[2] : null;
        },
        closePopScene(){
            this.loading = false;
            this.popUpCanvasToggle = false;
            this.engine.runRenderLoop(()=>{
                this.scene.render();
                this.engine.resize();
            });
            this.popEngine.stopRenderLoop();
            this.popEngine.dispose();
            setTimeout(()=>{
                this.loading = false;
            }, 750);
        },
        openWebsite(){
            window.open(this.attachedWebsite);
        },
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        blockchainLike1(){
            var myFrame = document.getElementById("myFrame");
            var iframeSrc = myFrame.src.toString();
            let param = {
                target: iframeSrc,
                interact: 1
            }
            console.log(param);
            exhInteract(param);
        },
        blockchainLike0(){
            var myFrame = document.getElementById("myFrame");
            var iframeSrc = myFrame.src.toString();
            let param = {
                target: iframeSrc,
                interact: 0
            }
            console.log(param);
            exhInteract(param);
        },
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        switchRoom(index){
            if(String(index)===this.$route.query.room) return;
            this.$router.push({name: 'Exhibitions', query:{id: this.$route.query.id, room: index}});
            window.location.href = location.protocol+'//'+window.location.host+'/exhibition'+'?'+`id=${this.$route.query.id}`+'&'+`room=${index}`;
        },
        changeCameraRadius(e){
            this.cameraRadius = e;
            this.camera.radius = this.cameraRadius;
        },
        enterLoginPage(){
            this.$router.push({name: "UserLogin", query:{
                eid: this.$route.query.id,
                room: this.$route.query.room,
            }})
        },
        switchMenuState(state, toggleBehavior=true){
            this.extendMenu = false;
            if(this.menuState===state&&this.menuToggle&&toggleBehavior){
                this.menuToggle = false;
                return;
            }
            switch (state) {
                case this.MENU_STATE.BLOCK:
                    break;
                case this.MENU_STATE.CHAT:
                    break;
                case this.MENU_STATE.CONTACT:
                    break;
            }
            this.menuState = state;
            this.menuToggle = true;
        },
        keydownEvent(e){
            if(e.keyCode===13){
                if(!this.chatMessage.length) return;
                this.sendChatMessage();
            }
        },
        sendChatMessage(){
            this.updateChatMessage({
                name: this.nickname,
                messages: this.chatMessage,
                room: this.$route.query.id
                //theme: this.currentTheme,
            });
        },
        async updateChatMessage(data={}, receiver=false){
            if(!data) return;
            if(data.theme!==this.currentTheme) return;
            let now = new Date();
            let month = (now.getMonth()+1).length>1?(now.getMonth()+1):'0'+(now.getMonth()+1);
            let day = (now.getDate().length>1)?(now.getDate()):now.getDate();
            let time = now.getHours() + ":" + now.getMinutes();
            let date = now.getFullYear()+'/'+month+'/'+day+' '+time;
        
            await this.chatroomData.push({
                date: date,
                name: data.name,
                messages: data.messages,
                room: this.$route.query.id,
            });

            if(!receiver){
                //data.theme = this.currentTheme;//SET THEME
                //this.SEND_MESSAGE =  this.$route.query.id;
                this.socket.emit("send_message", data);
                this.chatMessage = '';
            }
            const chats = document.getElementsByClassName('chat-container');
            const lastChat = chats[chats.length-1]||null;
            if(lastChat){
                chats[chats.length-1].scrollIntoView({behavior: 'smooth'});
            }
        },
        shotPhoto(){
            let vm = this;
            this.extendMenu = false;
            Babylon.Tools.CreateScreenshot(engine, camera, { width: window.innerWidth, height: window.innerHeight }, function (data) {
                vm.screenShotSrc = data;
            });
        },
        closeScreenShot(){
            this.screenShotSrc = "";
        },
        logoutDialog(){
            if(this.login){
                this.$refs.dialogLogout.active(); 
            }else{
                let routeData = this.$router.resolve({name: 'LandingPage'});
                window.open(routeData.href, '_blank');
            }
        },
        confirmLogout(){
            if(this.login){
                this.setLogout();
                this.$message({
                    showClose: true,
                    message: `Logout`,
                    type: 'success',
                    duration: 1500,
                    onClose:()=>{
                        this.$store.dispatch('loader/show');
                        //window.location.href = location.protocol+'//'+window.location.host+'/landing';
                        this.$router.push("/landing");
                    }
                });
            }
        },
    },
    created(){
        this.$store.dispatch('loader/show');
    },
    mounted(){
        this.init();
        this.getUserData();
    },
    computed:{
        currentRoom(){
            return this.$route.query.room;
        },
        userId(){
            return localStorage.getItem("userId");
            // return this.$route.query.uid;
        },
        login(){
            const uid = localStorage.getItem('userId');
            const token = localStorage.getItem('token');
            if(uid&&token){
                return true;
            }
            else{
                return false;
            }
        },
        menuTitle(){
            if(this.menuState===this.MENU_STATE.INTRO){
                return "Block Intro";
            }
            else if(this.menuState===this.MENU_STATE.CONTACT){
                return "Contact";
            }
            else if(this.menuState===this.MENU_STATE.CHAT){
                return "Chat";
            }
            else if(this.menuState===this.MENU_STATE.ACCOUNT_INFO){
                return "Account Info";
            }
            else{
                return "";
            }
        }
    },
    destroyed(){
        if(this.engine){
            this.engine.stopRenderLoop();
            this.engine.dispose();
        }
        if(this.popEngine){
            this.popEngine.stopRenderLoop();
            this.popEngine.dispose();
        }
    },
}
</script>
